import 'normalize.css';
import '../Style/Default.css';
import Vue from 'vue';
import Enumerable from 'linq';
import { alertBox } from './Alert';
import { BaseUrl, Version } from './Base';
import axios from 'axios';
import url from 'domurl';
import { Howl, Howler } from 'howler';

let UrlInfo = new url();
let Verify_Code = UrlInfo.query.Verify_Code;
let ReSendVerifyMail = UrlInfo.query.ReSendVerifyMail;
let Forgot_Code = UrlInfo.query.Forgot_Code;

document.addEventListener('keydown', function (event) {
  console.log(event);
  if ((event.metaKey || event.ctrlKey) && event.code === 'KeyB') {
    location.href = BaseUrl + 'backstage';
  }
});
window.onload = function () {
  let vue_object = new Vue({
    el: '#app',
    data: {
      ShowPwd: false,
      Version: Version,
      bgm: new Howl({
        src: [`../Sound/Happy Upbeat.mp3?_=${Version}`],
        autoplay: true,
        loop: true,
        volume: 0.03,
        html5: true,
      }),
      bgmStatus: true,
      hoverSound: new Howl({
        src: [`../Sound/11 字幕卡音效 2.mp3?_=${Version}`],
        volume: 0.03,
      }),
      RegisterContainer: false,
      GameRulesPage: 1,
      LoginContainer: false,
      ForgotContainer: false,
      ContactUsContainer: false,
      ReSendVerifyMailContainer: false,
      ResetPasswordContainer: false,
      GameRulesContainer: false,
      Register: {
        Account: '',
        Name: '',
        Password: '',
        RePassword: '',
        Identity_Id: 0,
        Sex: 0,
        Twid_Frist: '',
        BirthDay: '2010-01-01',
        Email: '',
        NickName: '',
        WechatID: '',
        ParentAgree: false,
        School: '',
        School_Other: '',
        Grade: '',
        Grade_Other: '',
        Recommender: '',
        Captcha: '',
      },
      ContactUsData: {
        Name: '',
        Email: '',
        Subject: '',
        Message: '',
        Captcha: '',
      },
      playSound: false,
      BaseUrl: BaseUrl,
      CaptchaImg: BaseUrl + 'api/Users/GetCaptcha?_=' + new Date().getTime(),
      AgreeGameRules: false,
    },
    methods: {
      Init() {
        let my = this;
        //預設首頁為自動登出
        axios
          .get(BaseUrl + 'api/Users/LogOut')
          .then((res) => {
            if (!res.data.Success) throw new Error(res.data.Message);
          })
          .catch((err) => {});

        // 將預設隱藏的元素移除Class，轉交給Vue控
        Enumerable.from(document.querySelectorAll('.defaultHide')).forEach(
          function (DOM) {
            DOM.classList.remove('defaultHide');
          }
        );

        window.addEventListener('mousedown', function () {
          if (my.playSound == true) return;
          my.playSound = true;
          my.hoverSound.play();
          my.playSound = false;
        });

        if (Verify_Code != undefined) {
          axios
            .get(`${BaseUrl}api/Users/User_Verify/${Verify_Code}`)
            .then((res) => {
              if (!res.data.Success) throw new Error(res.data.Message);
              alertBox({
                Html: `<span class="text-green-700">${res.data.Message}</span>`,
                OnOK: function () {
                  my.LoginShow();
                },
              });
            })
            .catch((err) => {
              alertBox({
                Html: `<span class="text-red-700">${err}</span>`,
                OnOK: function () {
                  location.href = BaseUrl + 'Default.html';
                },
              });
            });
        }

        if (ReSendVerifyMail == 'true') {
          my.ReSendVerifyMailContainer = true;
        }

        if (Forgot_Code != undefined) {
          this.ChangeCaptcha();
          this.ResetPasswordContainer = true;
        }
      },
      RegisterShow() {
        let my = this;
        let btn = document.getElementById('Register');
        btn.disabled = false;
        if (!my.AgreeGameRules) {
          my.GameRulesPage = 2;
          my.GameRulesShow();
          alertBox({
            Html: `<span class="text-red-700">請詳細閱讀並且同意來玩家PK遊戲規則後，即可開始加入會員。</sapn>`,
          });
          return;
        }
        my.GameRulesHide();
        my.RegisterContainer = true;
      },
      RegisterHide() {
        this.RegisterClear();
        this.RegisterContainer = false;
      },
      LoginShow() {
        let btn = document.getElementById('LoginBtn');
        btn.disabled = false;
        this.ChangeCaptcha();
        this.LoginContainer = true;
      },
      LoginHide() {
        this.RegisterClear();
        this.LoginContainer = false;
      },
      ForgotShow() {
        let btn = document.getElementById('ForgotBtn');
        btn.disabled = false;
        this.ChangeCaptcha();
        this.ForgotContainer = true;
      },
      ForgotHide() {
        this.RegisterClear();
        this.ForgotContainer = false;
      },
      LoginBtn() {
        let btn = document.getElementById('LoginBtn');
        if (btn.disabled) return;
        btn.disabled = true;
        axios
          .post(BaseUrl + 'api/Users/Login', this.Register)
          .then((res) => {
            if (!res.data.Success) throw JSON.stringify(res.data);
            location.href = BaseUrl + 'Index.html';
            btn.disabled = false;
          })
          .catch((err) => {
            let error_data = JSON.parse(err.replace('Error:', ''));
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-red-700">${error_data.Message}</span>`,
              OnOK: function () {
                if (error_data.Path != '') {
                  location.href = error_data.Path;
                }
              },
            });
          });
      },
      ContactUsShow() {
        this.ChangeCaptcha();
        this.ContactUsContainer = true;
      },
      ContactUsHide() {
        this.RegisterClear();
        this.ContactUsContainer = false;
      },
      soundPlay() {
        let my = this;
        if (my.bgm.playing()) {
          my.bgm.pause();
          my.bgmStatus = false;
        } else {
          my.bgm.play();
          my.bgmStatus = true;
        }
      },
      RegisterBtn() {
        let btn = document.getElementById('Register');
        btn.disabled = true;
        let my = this;
        axios
          .post(`${BaseUrl}api/Users/Register`, this.Register)
          .then((res) => {
            if (!res.data.Success) {
              throw new Error(res.data.Message);
            }
            alertBox({
              Html: `<span class="text-green-700">${res.data.Message}</span>`,
              OnOK: function () {
                btn.disabled = false;
              },
            });
            my.RegisterHide();
          })
          .catch((err) => {
            alertBox({
              Html: `<span class="text-red-700 font-bold">${err}</span>`,
              OnOK: function () {
                btn.disabled = false;
              },
            });
          });
      },
      ReSendVerifyMailHide() {
        this.ReSendVerifyMailContainer = false;
      },
      //變更驗證碼
      ChangeCaptcha() {
        this.CaptchaImg =
          BaseUrl + 'api/Users/GetCaptcha?_=' + new Date().getTime();
      },
      ReSendVerifyMailBtn() {
        let btn = document.getElementById('ReSendVerifyMailBtn');
        btn.disabled = true;
        let my = this;
        axios
          .post(BaseUrl + 'api/Users/ReSendVerifyMail', this.Register)
          .then((res) => {
            if (!res.data.Success) throw new Error(res.data.Message);
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-green-700">${res.data.Message}</span>`,
              OnOK: function () {
                my.ReSendVerifyMailContainer = false;
              },
            });
          })
          .catch((err) => {
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-red-700">${err}</span>`,
            });
          });
      },
      RegisterClear() {
        this.Register = {
          Account: '',
          Name: '',
          Password: '',
          RePassword: '',
          Identity_Id: 0,
          Sex: 0,
          Twid_Frist: '',
          BirthDay: '2010-01-01',
          Email: '',
          NickName: '',
          WechatID: '',
          ParentAgree: false,
          School: '',
          School_Other: '',
          Grade: '',
          Grade_Other: '',
          Recommender: '',
          Captcha: '',
        };

        this.ContactUsData = {
          Name: '',
          Email: '',
          Subject: '',
          Message: '',
        };
      },
      ForgotBtn() {
        let btn = document.getElementById('ForgotBtn');
        btn.disabled = true;
        let my = this;
        axios
          .post(BaseUrl + 'api/Users/ForgotPassword', this.Register)
          .then((res) => {
            if (!res.data.Success) throw new Error(res.data.Message);
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-green-700">${res.data.Message}</span>`,
              OnOK: function () {
                my.ForgotHide();
              },
            });
          })
          .catch((err) => {
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-red-700">${err}</span>`,
            });
          });
      },
      ResetPasswordHide() {
        this.RegisterClear();
        this.ResetPasswordContainer = false;
      },
      ResetPasswordBtn() {
        let btn = document.getElementById('ResetPasswordBtn');
        btn.disabled = true;
        let my = this;
        axios
          .post(BaseUrl + 'api/Users/ReSetPassword/' + Forgot_Code, my.Register)
          .then((res) => {
            if (!res.data.Success) throw new Error(res.data.Message);
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-green-700">${res.data.Message}<span>`,
              OnOK: function () {
                my.ResetPasswordContainer = false;
              },
            });
          })
          .catch((err) => {
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-red-700">${err}<span>`,
            });
          });
      },
      GameRulesShow() {
        this.GameRulesContainer = true;
      },
      GameRulesHide() {
        this.GameRulesPage = 1;
        this.GameRulesContainer = false;
      },
      ContactUsBtn() {
        let btn = document.getElementById('ContactUsBtn');
        btn.disabled = true;
        let my = this;
        axios
          .post(BaseUrl + 'api/Users/ContactUs', my.ContactUsData)
          .then((res) => {
            if (!res.data.Success) throw new Error(res.data.Message);
            btn.disabled = false;
            alertBox({
              Html: `<span class="text-green-700">${res.data.Message}</span>`,
              OnOK: function () {
                my.ContactUsHide();
              },
            });
          })
          .catch((err) => {
            btn.disabled = false;

            alertBox({
              Html: `<span class="text-red-700">${err}</span>`,
            });
          });
      },
    },
    mounted() {},
  });
  vue_object.Init();
};
